exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anki-alternative-js": () => import("./../../../src/pages/anki-alternative.js" /* webpackChunkName: "component---src-pages-anki-alternative-js" */),
  "component---src-pages-delete-js": () => import("./../../../src/pages/delete.js" /* webpackChunkName: "component---src-pages-delete-js" */),
  "component---src-pages-exams-exam-landing-content-name-js": () => import("./../../../src/pages/exams/{ExamLandingContent.name}.js" /* webpackChunkName: "component---src-pages-exams-exam-landing-content-name-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-help-localfiles-js": () => import("./../../../src/pages/help-localfiles.js" /* webpackChunkName: "component---src-pages-help-localfiles-js" */),
  "component---src-pages-help-webpage-js": () => import("./../../../src/pages/help-webpage.js" /* webpackChunkName: "component---src-pages-help-webpage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-flashcards-in-chrome-js": () => import("./../../../src/pages/make-flashcards-in-chrome.js" /* webpackChunkName: "component---src-pages-make-flashcards-in-chrome-js" */),
  "component---src-pages-make-flashcards-in-notion-js": () => import("./../../../src/pages/make-flashcards-in-notion.js" /* webpackChunkName: "component---src-pages-make-flashcards-in-notion-js" */),
  "component---src-pages-making-good-flashcards-js": () => import("./../../../src/pages/making-good-flashcards.js" /* webpackChunkName: "component---src-pages-making-good-flashcards-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchase-completed-js": () => import("./../../../src/pages/purchase_completed.js" /* webpackChunkName: "component---src-pages-purchase-completed-js" */),
  "component---src-pages-quizlet-alternative-js": () => import("./../../../src/pages/quizlet-alternative.js" /* webpackChunkName: "component---src-pages-quizlet-alternative-js" */),
  "component---src-pages-spaced-repetition-for-teachers-js": () => import("./../../../src/pages/spaced-repetition-for-teachers.js" /* webpackChunkName: "component---src-pages-spaced-repetition-for-teachers-js" */),
  "component---src-pages-uninstall-js": () => import("./../../../src/pages/uninstall.js" /* webpackChunkName: "component---src-pages-uninstall-js" */),
  "component---src-pages-what-is-spaced-repetition-js": () => import("./../../../src/pages/what-is-spaced-repetition.js" /* webpackChunkName: "component---src-pages-what-is-spaced-repetition-js" */)
}

